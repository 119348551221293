import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OwnerOperatorMenuComponent } from './owner-operator-menu.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [OwnerOperatorMenuComponent],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    OwnerOperatorMenuComponent
  ]
})
export class OwnerOperatorMenuModule { }
