// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  envName: 'qa',
  authUserUrl: 'http://localhost:9080/rfmRefreshApp/api/login',
  masterMenuListUrl: 'http://localhost:9080/rfmRefreshApp/api/listMiGroup',
  restaurantListUrl: 'http://localhost:9080/rfmRefreshApp/api/listRest',
  priceDataUrl: 'http://localhost:9080/rfmRefreshApp/api/getPriceData',
  treeDataUrl: 'http://localhost:9080/rfmRefreshApp/api/getTreeGenerator',
  generateAuditLog: 'http://localhost:9080/rfmRefreshApp/api/generateAuditLog',
  auditUsers: 'http://localhost:9080/rfmRefreshApp/api/listSearchUser',
  auditDropdownValues: 'http://localhost:9080/rfmRefreshApp/api/getDropDownValues',
  sessionLiveImageUrl: 'http://localhost:9080/rfm2OnlineApp/images/approve.jpg?',
  packageScheduleUrl: 'http://localhost:9080/rfmRefreshApp/api/performSearch',
  marketRegionCoopList: 'assets/mock-json/market-region-coop.json',
  massMenuItemDeleteDataUrl: 'http://localhost:9080/rfmRefreshApp/api/getMenuItem',
  deletePkgScheduleUrl: 'http://localhost:9080/rfmRefreshApp/api/deletePkgSchedule',
  restDBParamCustomReport: 'http://localhost:9080/rfmRefreshApp/api/restDBParamCustomReport',
  //RFM-25232, loader timout in milliseconds
  ng4LoaderSpinnerTimeout: 15 * 60 * 1000,
  //RFMP-21083 product redirection
  productURL: "https://us-restaurant-apps-dev.mcd.com/product",
  categoryCloudFrontURL: "http://localhost:4200/rfm-category/",
  dataValidationEnv:'dev',
  dataValidationAppId:'2e2givfmerm8fmkat7bhpismq0',
  dataValidationAppSecret:'o60bh286bsq50e6967uj78i554t5ojad7scf9h20fvvoh4j4c8t',
  dataValidationCognitoURL:'https://mcd-dev-restaurant-api.auth.us-east-1.amazoncognito.com',
  dataValidationLoginURL:'https://mcd-dev-restaurant-api.auth.us-east-1.amazoncognito.com/oauth2/authorize?identity_provider=/rmsv2/us-dev/gassamlprovider',
  dataValidationCloudFrontURL:'https://d7l2urmlgb38l.cloudfront.net'  
};
